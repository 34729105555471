<template>
  <div>

    <button class="button" type="button" @click="openDialog">
      Login
    </button>

    <app-modal title="Login" :open="showLogin" @dismiss="showLogin = false">
      <div>
        <form @submit.prevent="performLogin">

          <div v-if="loginMessage" class="notification is-danger">
            {{loginMessage}}
          </div>

          <div class="field">
            <label class="label">Username</label>
            <div class="control has-icons-left">
              <input class="input" type="text" placeholder="username" ref="usernameInput" v-model="username">
              <app-icon icon="person" size="sm" class="is-left"></app-icon>
            </div>
          </div>

          <div class="field">
            <label class="label">Password</label>
            <div class="control has-icons-left">
              <input class="input" type="password" placeholder="password" v-model="password">
              <app-icon icon="lock-locked" size="sm" class="is-left"></app-icon>
            </div>
          </div>

          <div class="field is-grouped">
            <div class="control">
              <button type="submit" class="button is-primary" :disabled="!enableSubmit">Login</button>
            </div>
            <div class="control">
              <button type="button" class="button is-secondary" @click="showLogin = false">Cancel</button>
            </div>
          </div>

        </form>
      </div>
    </app-modal>

  </div>
</template>

<script setup>

  import { computed, nextTick, ref, useTemplateRef } from "vue";
  import { useAppConfigStore } from "../stores/appConfig";
  import { useLoadResource } from "../lib/useLoadResource";

  const appConfig = useAppConfigStore();
  const { loadResource } = useLoadResource();

  const userNameElement = useTemplateRef("usernameInput");

  const showLogin = ref(false);
  const error = ref('');
  const username = ref("");
  const password = ref("");

  const loginMessage = computed(() => appConfig.loginMessage);
  const enableSubmit = computed(() => username.value !== "" && password.value !== "" && !appConfig.isLoading);

  function openDialog() {
    showLogin.value = true;
    nextTick(() => {
      userNameElement.value.focus();
    })
  }

  function performLogin() {
    if (username.value !== '' && password.value !== '') {
      const params = {username: username.value, password: password.value};

      loadResource(
          appConfig.login(params)
              .then(data => {
                if (data.success) {
                  showLogin.value = false;
                }
              })
      );
    }
  }

</script>

<style lang="scss" scoped>
</style>