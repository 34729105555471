<template>
  <div>
    <div v-if="recipe === null">
      Loading...
    </div>
    <div v-else>
      <h1 class="title">{{ recipe.name }}</h1>
      <div class="subtitle tags">
        <span v-for="tag in recipe.tags" :key="tag" class="tag is-medium">{{tag}}</span>
      </div>
      <div class="tags">
        <span v-if="isScaled" class="tag is-large">{{recipe.converted_scale}} X</span>
        <span v-if="recipe.converted_system !== null" class="tag is-large">{{recipe.converted_system}}</span>
        <span v-if="recipe.converted_unit !== null" class="tag is-large">{{recipe.converted_unit}}</span>
      </div>
      <hr>
      <recipe-show :recipe="recipe"></recipe-show>
    </div>

    <router-link v-if="appConfig.isLoggedIn" class="button" :to="{name: 'edit_recipe', params: { id: recipeId }}">Edit</router-link>
    <router-link class="button" to="/">Back</router-link>
  </div>
</template>

<script setup>

  import { computed, ref, watch } from "vue";
  import { useRoute } from "vue-router";
  import RecipeShow from "./RecipeShow";
  import api from "../lib/Api";
  import { useLoadResource } from "../lib/useLoadResource";
  import { useAppConfigStore } from "../stores/appConfig";

  const appConfig = useAppConfigStore();
  const route = useRoute();
  const { loadResource } = useLoadResource();
  const recipe = ref(null);

  const recipeId = computed(() => route.params.id);
  const scale = computed(() => route.query.scale || null);
  const system = computed(() => route.query.system || null);
  const unit = computed(() => route.query.unit || null);
  const isScaled = computed(() => recipe.value?.converted_scale?.length !== undefined && recipe.value.converted_scale.length > 0 && recipe.value.converted_scale !== "1");

  watch(
      () => route.query,
      () => refreshData(),
      { immediate: true }
  );

  watch(
      () => recipe.value?.name,
      (newRecipe) => {
        if (newRecipe) {
          document.title = `${newRecipe.name}`;
        }
      }
  )

  function refreshData() {
    loadResource(
        api.getRecipe(recipeId.value, scale.value, system.value, unit.value, data => recipe.value = data)
    );
  }

</script>

<style lang="scss" scoped>
</style>
