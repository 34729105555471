import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useRoute } from "vue-router";
import api from "../lib/Api";

export const useAppConfigStore = defineStore('appConfig', () => {
  const authChecked = ref(false);
  const errorObject = ref(null);
  const initialLoad = ref(false);
  const loadingCount = ref(0);
  const loginMessage = ref(null);
  const updateAvailable = ref(false);
  const userObject = ref(null);
  const route = useRoute();

  const isLoading = computed(() => {
    return loadingCount.value > 0;
  });

  const isLoggedIn = computed(() => {
    return userObject.value !== null;
  });

  const isAdmin = computed(() => {
    return userObject.value?.admin === true;
  });

  const error = computed({
    get: () => errorObject.value,
    set: (val) => {
      console.log(val);
      errorObject.value = val;
    }
  });

  const user = computed({
    get: () => userObject.value,
    set: (val) => {
      userObject.value = val;
      authChecked.value = true;
    }
  });

  function setError(value) {
    error.value = value;
  }

  function setLoading(value) {
    if (value) {
      loadingCount.value = loadingCount.value + 1;
    } else {
      loadingCount.value = loadingCount.value - 1;
    }
  }

  async function updateCurrentUser() {
    user.value = await api.getCurrentUser();
    return user.value;
  }

  function login(authData) {
    return api.postLogin(authData.username, authData.password)
      .then(data => {
        if (data.success) {
          user.value = data.user;
          loginMessage.value = null;
        } else {
          user.value = null;
          loginMessage.value = data.message;
        }
        return data;
      });
  }

  function logout() {
    return api.getLogout()
      .then(() => {
        user.value = null;
      });
  }

  return {
    authChecked,
    error,
    initialLoad,
    loadingCount,
    loginMessage,
    updateAvailable,
    user,
    route,

    isAdmin,
    isLoading,
    isLoggedIn,

    login,
    logout,
    setError,
    setLoading,
    updateCurrentUser
  };
});
