<template>
  <div class="field">
    <label v-if="label.length" class="label is-small-mobile">{{ label }}</label>
    <div :class="controlClasses">
      <textarea v-if="isTextarea" :class="inputClasses" v-model="model" :disabled="disabled"></textarea>
      <input v-else :type="type" :class="inputClasses" v-model="model" :disabled="disabled">
      <app-icon class="is-right" icon="warning" v-if="validationError !== null"></app-icon>
    </div>
    <p v-if="helpMessage !== null" :class="helpClasses">
      {{ helpMessage }}
    </p>
  </div>
</template>

<script setup>

import { computed } from "vue";

const props = defineProps({
  label: {
    required: false,
    type: String,
    default: ""
  },
  modelValue: {
    required: false,
    type: [String, Number],
    default: ""
  },
  type: {
    required: false,
    type: String,
    default: "text"
  },
  disabled: {
    type: Boolean,
    default: false
  },
  validationError: {
    required: false,
    type: String,
    default: null
  }
});

const model = defineModel({
  type: [String, Number],
  default: ""
});

const isTextarea = computed(() => props.type === "textarea");
const controlClasses = computed(() => [
    "control",
    {
      "has-icons-right": props.validationError !== null
    }
  ]);

const inputClasses = computed(() =>[
    "is-small-mobile",
    {
      "textarea": isTextarea.value,
      "input": !isTextarea.value,
      "is-danger": props.validationError !== null
    }
  ]);

const helpMessage = computed(() => props.validationError);
const helpClasses = computed(() => [
  "help",
  {
    "is-danger": props.validationError !== null
  }
]);


</script>

<style lang="scss" scoped>
</style>