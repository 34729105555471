<template>
  <div>

    <food-edit :food="food" :validation-errors="validationErrors" action="Creating"></food-edit>

    <button type="button" class="button is-primary" @click="save">Save</button>
    <router-link class="button is-secondary" to="/food">Cancel</router-link>

  </div>
</template>

<script setup>

  import { reactive, ref } from "vue";
  import { useRouter } from "vue-router";
  import FoodEdit from "./FoodEdit";
  import api from "../lib/Api";
  import * as Errors from '../lib/Errors';
  import { useLoadResource } from "../lib/useLoadResource";

  const { loadResource } = useLoadResource();
  const router = useRouter();

  const validationErrors = ref({});
  const food = reactive({
    name: null,
    notes: null,
    ndbn: null,
    density: null,
    water: null,
    ash: null,
    protein: null,
    kcal: null,
    fiber: null,
    sugar: null,
    carbohydrates: null,
    calcium: null,
    iron: null,
    magnesium: null,
    phosphorus: null,
    potassium: null,
    sodium: null,
    zinc: null,
    copper: null,
    manganese: null,
    vit_c: null,
    vit_b6: null,
    vit_b12: null,
    vit_a: null,
    vit_e: null,
    vit_d: null,
    vit_k: null,
    cholesterol: null,
    lipids: null,
    food_units: []
  });

  function save() {
    validationErrors.value = {}
    loadResource(
        api.postFood(food)
            .then(() => router.push('/foods'))
            .catch(Errors.onlyFor(Errors.ApiValidationError, err => validationErrors.value = err.validationErrors()))
    );
  }

</script>

<style lang="scss" scoped>
</style>