<template>

  <div>

    <h1 class="title">User List</h1>

    <table class="table">
      <thead>
      <tr>
        <th>Name</th>
        <th>Email</th>
        <th>Admin?</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="u in userList" :key="u.id">
        <td>{{u.name}}</td>
        <td>{{u.email}}</td>
        <td>{{u.admin}}</td>
        <td>
          <button type="button" class="button is-danger">X</button>
        </td>
      </tr>
      </tbody>
    </table>

  </div>

</template>

<script setup>

  import { onBeforeMount, ref } from "vue";
  import { useLoadResource } from "../lib/useLoadResource";
  import api from "../lib/Api";

  const { loadResource } = useLoadResource();
  const userList = ref([]);

  onBeforeMount(() => {
    loadResource(
        api.getAdminUserList()
            .then(list => userList.value = list)
    );
  });

</script>

<style lang="scss" scoped>

</style>