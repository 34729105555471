<template>
  <div>

    <div v-if="food === null">
      Loading...
    </div>
    <div v-else>
      <food-edit :food="food" :validation-errors="validationErrors"></food-edit>
    </div>

    <button type="button" class="button is-primary" @click="save">Save</button>
    <router-link class="button is-secondary" to="/foods">Cancel</router-link>

  </div>
</template>

<script setup>

  import { computed, onBeforeMount, ref } from "vue";
  import { useRoute, useRouter } from "vue-router";
  import FoodEdit from "./FoodEdit";
  import api from "../lib/Api";
  import * as Errors from '../lib/Errors';
  import { useLoadResource } from "../lib/useLoadResource";

  const { loadResource } = useLoadResource();
  const router = useRouter();
  const route = useRoute();

  const food = ref(null);
  const validationErrors = ref({});
  const foodId = computed(() => route.params.id);

  onBeforeMount(() => {
    loadResource(
        api.getFood(foodId.value)
            .then(data => { food.value = data; return data; })
    );
  });

  function save() {
    validationErrors.value = {};
    loadResource(
        api.patchFood(food.value)
            .then(() => router.push({name: 'food', params: {id: foodId.value }}))
            .catch(Errors.onlyFor(Errors.ApiValidationError, err => validationErrors.value = err.validationErrors()))
    );
  }

</script>

<style lang="scss" scoped>
</style>