<template>
  <Teleport to="body">
    <div :class="['modal', { 'is-wide': wide, 'is-active': open && error === null }]">
      <div class="modal-background" @click="close"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <slot name="title">
            <p class="modal-card-title">{{ title }}</p>
            <app-icon class="close-button" icon="x" aria-label="close" @click="close"></app-icon>
          </slot>
        </header>

        <section class="modal-card-body">
          <slot></slot>
        </section>

        <footer class="modal-card-foot">
          <slot name="footer">
          </slot>
        </footer>
      </div>
    </div>
  </Teleport>
</template>

<script setup>

  import { computed } from "vue";
  import { useAppConfigStore } from "../stores/appConfig";

  const emit = defineEmits(["dismiss"]);

  const props = defineProps({
    open: {
      type: Boolean,
      default: false
    },
    title: String,
    wide: {
      type: Boolean,
      default: false
    }
  });

  const appConfig = useAppConfigStore();
  const error = computed(() => appConfig.error);

  function close() {
    emit("dismiss");
  }

</script>

<style lang="scss" scoped>

  .close-button {
    cursor: pointer;
  }

</style>