<template>
  <div class="field">
    <div class="control">
      <input type="text" class="input" :placeholder="placeholder" :value="text === null ? '' : text" @input="userUpdateText($event.target.value)">
    </div>
  </div>
</template>

<script setup>

import { ref } from "vue";
import debounce from "lodash/debounce";

const emit = defineEmits(["update:modelValue"]);

const props = defineProps({
  placeholder: {
    required: false,
    type: String,
    default: ""
  },

  modelValue: {
    required: false,
    type: String,
    default: ""
  }
});

const text = ref(null);

const triggerInput = debounce(function() {
      emit("update:modelValue", text.value);
    },
    250,
    { leading: false, trailing: true })

function userUpdateText(newText) {
  if (text.value !== newText) {
    text.value = newText;
    triggerInput();
  }
}

function propUpdateText(newText) {
  if (text.value === null && text.value !== newText) {
    text.value = newText;
  }
}

</script>