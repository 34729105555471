<template>
  <div>
    <h1 class="title">Ingredients</h1>

    <div class="buttons">
      <router-link v-if="appConfig.isLoggedIn" :to="{name: 'new_food'}" class="button is-primary">Create Ingredient</router-link>
    </div>

    <app-pager :current-page="currentPage" :total-pages="totalPages" paged-item-name="food" @changePage="changePage"></app-pager>

    <table class="table is-fullwidth is-narrow">
      <thead>
      <tr>
        <th>Name</th>
        <th>USDA</th>
        <th>KCal per 100g</th>
        <th>Density (oz/cup)</th>
        <th></th>
      </tr>
      <tr>
        <th>
          <div class="field">
            <div class="control">
              <input type="text" class="input" placeholder="search names" v-model="search.name">
            </div>
          </div>
        </th>
        <th colspan="4"></th>
      </tr>
      </thead>
      <transition-group tag="tbody" name="fade" mode="out-in">
      <tr v-for="i in foods" :key="i.id">
        <td><router-link :to="{name: 'food', params: { id: i.id } }">{{i.name}}</router-link></td>
        <td><app-icon v-if="i.usda" icon="check"></app-icon></td>
        <td>{{i.kcal}}</td>
        <td>{{i.density}}</td>
        <td>
          <template v-if="appConfig.isLoggedIn">
            <router-link class="button" :to="{name: 'edit_food', params: { id: i.id } }">
              <app-icon icon="pencil"></app-icon>
            </router-link>
            <button type="button" class="button is-danger" @click="deleteFood(i)">
              <app-icon icon="x"></app-icon>
            </button>
          </template>
        </td>
      </tr>
      </transition-group>
    </table>

    <app-pager :current-page="currentPage" :total-pages="totalPages" paged-item-name="food" @changePage="changePage"></app-pager>

    <div class="buttons">
      <router-link v-if="appConfig.isLoggedIn" :to="{name: 'new_food'}" class="button is-primary">Create Ingredient</router-link>
    </div>

    <app-confirm :open="showConfirmFoodDelete" title="Delete Ingredient?" :message="confirmFoodDeleteMessage" @cancel="foodDeleteCancel" @confirm="foodDeleteConfirm"></app-confirm>

  </div>
</template>

<script setup>

  import { computed, reactive, ref, watch } from "vue";
  import api from "../lib/Api";
  import debounce from "lodash/debounce";
  import { useAppConfigStore } from "../stores/appConfig";
  import { useLoadResource } from "../lib/useLoadResource";

  const appConfig = useAppConfigStore();
  const { loadResource } = useLoadResource();

  const foodData = ref(null);
  const foodForDeletion = ref(null);
  const search = reactive({
    page: 1,
    per: 25,
    name: null
  });

  const foods = computed(() => foodData.value?.foods || []);
  const totalPages = computed(() => foodData.value?.total_pages || 0);
  const currentPage = computed(() => foodData.value?.current_page || 0);
  const showConfirmFoodDelete = computed(() => foodForDeletion.value !== null);
  const confirmFoodDeleteMessage = computed(() => {
    if (foodForDeletion.value !== null) {
      return `Are you sure you want to delete ${foodForDeletion.value.name}?`;
    } else {
      return "??";
    }
  });

  const getList = debounce(function() {
    return loadResource(
        api.getFoodList(search.page, search.per, search.name)
            .then(data => foodData.value = data)
    );
  }, 500, {leading: true, trailing: true});

  watch(search,
      () => getList(),
      {
        deep: true,
        immediate: true
      }
  );

  function changePage(idx) {
    search.page = idx;
  }

  function deleteFood(food) {
    foodForDeletion.value = food;
  }

  function foodDeleteCancel() {
    foodForDeletion.value = null;
  }

  function foodDeleteConfirm() {
    if (foodForDeletion.value !== null) {
      loadResource(
          api.deleteFood(foodForDeletion.value.id).then(res => {
            foodForDeletion.value = null;
            return getList();
          })
      );
    }
  }

</script>