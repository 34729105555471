<template>
  <div id="app">
    <app-progress-bar></app-progress-bar>
    <app-navbar></app-navbar>
    <section id="main" class="">
      <div class="container">
        <router-view v-slot="{ Component }">
          <transition name="fade" mode="out-in">
            <component v-if="!hasError" :is="Component" />
            <div v-else>
              <h1>Error!</h1>
              <p>{{ appConfig.error }}</p>
            </div>
          </transition>
        </router-view>
      </div>
    </section>
  </div>
</template>

<script setup>
  import { computed, nextTick, onMounted, onUnmounted, ref, watch } from "vue";
  import { useGlobalTweenGroup } from "../lib/useGlobalTweenGroup";
  import { useAppConfigStore } from "../stores/appConfig";
  import { useLoadResource } from "../lib/useLoadResource";
  import { useCheckAuthentication } from "../lib/useCheckAuthentication";
  import AppProgressBar from "./AppProgressBar.vue";

  const globalTweenGroup = useGlobalTweenGroup();
  let animationLoop = true;

  const appConfig = useAppConfigStore();
  const hasError = computed(() => appConfig.error !== null);

  const { loadResource } = useLoadResource();
  const { checkAuthentication } = useCheckAuthentication(loadResource);

  watch(
      () => appConfig.initialLoad,
      (val) => {
        if (val) {
          nextTick(() => document.body.classList.remove("loading"));
        }
      },
      { immediate: true }
  );

  onMounted(() => {
    // Setup global animation loop
    function animate() {
      if (animationLoop) {
        globalTweenGroup.update();
        requestAnimationFrame(animate);
      }
    }
    animate();

    if (appConfig.user === null && appConfig.authChecked === false) {
      checkAuthentication();
    }
  });

  onUnmounted(() => {
    animationLoop = false;
  });

</script>