<template>
  <div>
    <div class="buttons">
      <button type="button" class="button is-primary" @click="addNote">Add Note</button>
    </div>

    <app-modal title="Add Note" :open="editNote !== null" @dismiss="cancelNote">
      <note-edit v-if="editNote !== null" :note="editNote" @save="saveNote" @cancel="cancelNote"></note-edit>
    </app-modal>

    <table class="table">
      <thead>
      <tr>
        <th>Note</th>
        <th>Date</th>
        <th></th>
      </tr>
      </thead>

      <tbody>
      <tr v-for="n in notes" :key="n.id">
        <td>
          {{ n.content }}
        </td>
        <td>
          <app-date-time :date-time="n.created_at"></app-date-time>
        </td>
        <td>
          <button type="button" class="button is-danger" @click="deleteNote(n)">
            <app-icon icon="x"></app-icon>
          </button>
        </td>
      </tr>
      </tbody>

    </table>
  </div>

</template>

<script setup>

  import { onBeforeMount, ref } from "vue";
  import api from "../lib/Api";
  import NoteEdit from "./NoteEdit";
  import { useLoadResource } from "../lib/useLoadResource";

  const { loadResource } = useLoadResource();
  const notes = ref([]);
  const editNote = ref(null);

  onBeforeMount(() => {
    refreshList();
  });

  function refreshList() {
    loadResource(
        api.getNoteList()
            .then(data => notes.value = data)
    );
  }

  function addNote() {
    editNote.value = { id: null, content: "" };
  }

  function saveNote() {
    loadResource(
        api.postNote(editNote.value)
            .then(() => {
              editNote.value = null;
              return refreshList();
            })
    );
  }

  function cancelNote() {
    editNote.value = null;
  }

  function deleteNote(n) {
    loadResource(
        api.deleteNote(n)
            .then(() => {
              return refreshList();
            })
    );
  }

</script>

<style lang="scss" scoped>
</style>