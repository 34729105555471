<template>
  <app-modal :open="open" :title="title" @dismiss="runCancel">
    <p class="is-size-5">{{ message }}</p>

    <template #footer>
      <div class="buttons">
        <button type="button" class="button is-primary" @click="runConfirm">OK</button>
        <button type="button" class="button" @click="runCancel">Cancel</button>
      </div>
    </template>
  </app-modal>
</template>

<script setup>

const emit = defineEmits(["cancel", "confirm"]);

const props = defineProps({
  message: {
    type: String,
    required: false,
    default: 'Are you sure?'
  },

  title: {
    type: String,
    required: false,
    default: "Confirm"
  },

  open: {
    type: Boolean,
    required: true
  }
});

function runConfirm() {
  emit("confirm");
}

function runCancel() {
  emit("cancel");
}

</script>