<template>
  <app-text-field :value="stringValue" @update:modelValue="input" :label="label" type="date"></app-text-field>
</template>

<script setup>

  import { computed } from "vue";
  import DateTimeUtils from "../lib/DateTimeUtils";

  const emit = defineEmits(["update:modelValue"]);
  const props = defineProps({
    modelValue: {
      required: false,
      type: [Date, String]
    },

    label: {
      required: false,
      type: String,
      default: null
    }
  });

  const stringValue = computed(() => {
    const d = DateTimeUtils.toDate(props.modelValue);
    return DateTimeUtils.formatDateForEdit(d);
  });

  function input(val) {
    let d = DateTimeUtils.toDate(val + " 00:00");
    emit("update:modelValue", d);
  }

</script>

<style lang="scss" scoped>

</style>