<template>

  <div class="dropdown-item" @mouseover="hovering = true" @mouseleave="hovering = false" :class="{hovered: hovering, 'is-active': active}" @click="selectList">
    <span>{{taskList.name}} ({{ taskList.task_items.length }})</span>
    <button @click.stop="confirmingDelete = true" class="button is-small is-danger is-pulled-right"><app-icon icon="x" size="sm"></app-icon></button>
    <div class="is-clearfix"></div>

    <app-modal :open="confirmingDelete" :title="'Delete ' + taskList.name + '?'" @dismiss="confirmingDelete = false">
      <button class="button is-danger" @click="deleteList">Confirm</button>
      <button class="button is-primary" @click="confirmingDelete = false">Cancel</button>
    </app-modal>

  </div>

</template>

<script setup>

  import { ref } from "vue";

  const emit = defineEmits(["select", "delete"]);

  const props = defineProps({
    taskList: {
      type: Object,
      required: true
    },

    active: {
      type: Boolean,
      required: false,
      default: false
    }
  });

  const hovering = ref(false);
  const confirmingDelete = ref(false);

  function selectList() {
    emit("select", props.taskList);
  }

  function deleteList() {
    confirmingDelete.value = false;
    emit("delete", props.taskList);
  }

</script>

<style lang="scss" scoped>

  @use "bulma/sass/utilities" as bulma;
  @use 'sass:color';

  div.dropdown-item {
    cursor: pointer;

    &.hovered {
      color: bulma.$black;
      background-color: bulma.$background;
    }

    &.is-active {
      color: color.invert(bulma.$link);
      background-color: bulma.$link;
    }
  }

</style>