<template>
  <div>
    <div class="field">
      <label class="label">Note</label>
      <div class="control">
        <textarea class="textarea" v-model="note.content"></textarea>
      </div>
    </div>

    <div class="buttons">
      <button type="button" class="button is-primary" :disabled="!canSave" @click="save">
        Save
      </button>

      <button type="button" class="button is-secondary" @click="cancel">
        Cancel
      </button>
    </div>
  </div>
</template>

<script setup>

import { computed } from "vue";

const emit = defineEmits(["save", "cancel"]);
const props = defineProps({
  note: {
    required: true,
    type: Object
  }
});

const canSave = computed(() => props.note?.content?.length);

function save() {
  emit("save", props.note);
}

function cancel() {
  emit("cancel");
}

</script>

<style lang="scss" scoped>
</style>