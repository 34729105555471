<template>
  <div class="progress-bar" :style="progressStyle"></div>
</template>

<script setup>

import { computed, ref, watch } from "vue";
import { useAppConfigStore } from "../stores/appConfig";
import TWEEN from '@tweenjs/tween.js';
import { useGlobalTweenGroup } from "../lib/useGlobalTweenGroup";

const appConfig = useAppConfigStore();

const showProgress = ref(false);
const loadingPercent = ref(0);
let animation = null;

const progressStyle = computed(() => {
  return {
    opacity: showProgress.value ? "1" : "0",
    width: `${loadingPercent.value}%`,
    height: "4px"
  };
});

watch(() => appConfig.isLoading, val => {
  if (val) {
    start();
  } else {
    stop();
  }
});

function start() {
  if (!animation) {
    showProgress.value = true;
    animation = new TWEEN.Tween({ percent: 0 }, useGlobalTweenGroup())
        .to({ percent: 90 })
        .easing(TWEEN.Easing.Quartic.Out)
        .duration(3000)
        .onUpdate(({ percent }) => { loadingPercent.value = percent; })
        .onComplete(({ percent }) => {})
        .start();
  }
}

function stop() {
  if (animation) {
    showProgress.value = false;
    animation.stop();
    animation = null;
  }
}

</script>

<style lang="scss" scoped>

  @use "bulma/sass/utilities" as bulma;

  .progress-bar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    background-color: bulma.$blue;
    transition: width 0.1s, opacity 0.3s;
  }
</style>
