<template>
  <div>

    <log-edit v-if="log.recipe !== null" :log="log" :validation-errors="validationErrors">
      <div class="buttons">
        <button type="button" class="button is-primary" @click="save">Save Log</button>
        <router-link class="button is-secondary" to="/">Cancel</router-link>
      </div>
    </log-edit>

    <div class="buttons">
      <button type="button" class="button is-primary" @click="save">Save Log</button>
      <router-link class="button is-secondary" to="/">Cancel</router-link>
    </div>

  </div>
</template>

<script setup>

  import { computed, onBeforeMount, reactive, ref } from "vue";
  import { useRoute, useRouter } from "vue-router";
  import LogEdit from "./LogEdit";
  import api from "../lib/Api";
  import * as Errors from '../lib/Errors';
  import { useLoadResource } from "../lib/useLoadResource";

  const { loadResource } = useLoadResource();
  const route = useRoute();
  const router = useRouter();

  const validationErrors = ref({});
  const log = reactive({
    date: null,
    rating: null,
    notes: null,
    recipe: null
  });

  const recipeId = computed(() => route.params.recipeId);

  onBeforeMount(() => {
    loadResource(
        api.getRecipe(recipeId.value, null, null, null, data => log.recipe = data)
    );
  });

  function save() {
    log.original_recipe_id = recipeId.value;
    validationErrors.value = {};

    loadResource(
        api.postLog(log)
            .then(() => router.push('/'))
            .catch(Errors.onlyFor(Errors.ApiValidationError, err => validationErrors.value = err.validationErrors()))
    );
  }

</script>

<style lang="scss" scoped>
</style>