<template>
  <div class="app-loading">
    Imagine I'm a spinner...
  </div>
</template>

<script setup>
</script>

<style lang="scss" scoped>

  .app-loading {
    position: absolute;
    display: none;
  }

</style>