<template>
  <nav class="navbar is-primary" role="navigation" aria-label="main navigation">
    <div class="container">
      <div class="navbar-brand">
        <a class="navbar-item" href="/">
          PARSLEY
        </a>
        <div class="navbar-burger" :class="{ 'is-active': menuActive}" @click="menuActive = !menuActive">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
      <div class="navbar-menu" :class="{ 'is-active': menuActive}">
        <div class="navbar-start">
          <a class="navbar-item" v-if="updateAvailable" href="#" @click.prevent="updateApp">UPDATE AVAILABLE!</a>
          <router-link to="/" class="navbar-item">Recipes</router-link>
          <router-link to="/foods" class="navbar-item">Ingredients</router-link>
          <router-link to="/calculator" class="navbar-item">Calculator</router-link>
          <router-link v-if="isLoggedIn" to="/logs" class="navbar-item">Log</router-link>
          <router-link v-if="isLoggedIn" to="/notes" class="navbar-item">Notes</router-link>
          <router-link v-if="isLoggedIn" to="/tasks" class="navbar-item">Tasks</router-link>
          <router-link to="/about" class="navbar-item">About</router-link>
          <router-link v-if="isAdmin" to="/admin/users" class="navbar-item">Admin</router-link>

        </div>
        <div class="navbar-end">
          <div class="navbar-item has-dropdown is-hoverable" >
            <div v-if="isLoggedIn">
              <a class="navbar-link" href="#" @click.prevent>
                {{ user.name }}
              </a>
              <div class="navbar-dropdown is-boxed">
                <router-link to="/user/edit" class="navbar-item">
                  Profile
                </router-link>
                <router-link to="/logout" class="navbar-item">Logout</router-link>
              </div>
            </div>
            <div v-else>
              <user-login class="navbar-link"></user-login>
              <div class="navbar-dropdown is-boxed">
                <router-link to="/user/new" class="navbar-item">Create Account</router-link>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script setup>

  import { ref, watch } from "vue";
  import UserLogin from "./UserLogin";
  import { storeToRefs } from "pinia";
  import { useAppConfigStore } from "../stores/appConfig";
  import { swUpdate } from "../lib/ServiceWorker";
  import { useRoute } from "vue-router";

  const appConfig = useAppConfigStore();
  const menuActive = ref(false);
  const route = useRoute();
  const { isAdmin, isLoggedIn, updateAvailable, user } = storeToRefs(appConfig);

  function updateApp() {
    swUpdate();
  }

  watch(
      () => [route, appConfig.user],
      () => menuActive.value = false
  );

</script>