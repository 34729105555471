<template>
  <div>
    <div v-if="log === null">
      Loading...
    </div>
    <div v-else>
      <log-show :log="log"></log-show>
    </div>

    <div class="buttons">
      <router-link v-if="appConfig.isLoggedIn" class="button" :to="{name: 'edit_log', params: { id: logId }}">Edit</router-link>
      <router-link class="button" to="/logs">Back</router-link>
    </div>
  </div>
</template>

<script setup>

  import { computed, onBeforeMount, ref } from "vue";
  import { useRoute } from "vue-router";
  import LogShow from "./LogShow";
  import api from "../lib/Api";
  import { useLoadResource } from "../lib/useLoadResource";
  import { useAppConfigStore } from "../stores/appConfig";

  const { loadResource } = useLoadResource();
  const route = useRoute();
  const appConfig = useAppConfigStore();

  const log = ref(null);
  const logId = computed(() => route.params.id);

  onBeforeMount(() => {
    loadResource(
        api.getLog(logId.value)
            .then(data => { log.value = data; return data; })
    );
  });

</script>

<style lang="scss" scoped>
</style>