<template>
  <div class="columns task-item-edit">

    <div class="field column">
      <label class="label is-small">Name</label>
      <div class="control">
        <input class="input is-small" type="text" placeholder="Name" v-model="taskItem.name" @keydown="inputKeydown" ref="nameInput">
      </div>
    </div>

    <div class="field column">
      <label class="label is-small">Quantity</label>
      <div class="control">
        <input class="input is-small" type="text" placeholder="Qty" v-model="taskItem.quantity" @keydown="inputKeydown">
      </div>
    </div>

    <div class="field column">
      <div class="control">
        <button class="button is-primary" @click="save">Add</button>
      </div>
    </div>

  </div>
</template>

<script setup>

  import { onMounted, useTemplateRef } from "vue";

  const emit = defineEmits(["save"]);
  const props = defineProps({
    taskItem: {
      required: true,
      type: Object
    }
  });

  const nameElement = useTemplateRef("nameInput");

  onMounted(() => focus());

  function inputKeydown(evt) {
    switch (evt.key) {
      case "Enter":
        evt.preventDefault();
        save();
    }
  }

  function save() {
    emit("save", props.taskItem);
  }

  function focus() {
    nameElement.value.focus();
  }

  defineExpose({
    focus
  });

</script>

<style lang="scss" scoped>

  .task-item-edit {

  }

</style>