<template>
  <span class="icon" :class="iconClasses" @click="$emit('click', $event)">
    <app-iconic-icon :icon="iconicIcon" :size="iconicSize" v-bind="iconicAttributes"></app-iconic-icon>
  </span>
</template>

<script>

  import { computed } from "vue";

  class IconData {
    constructor(iconicIcon, dataAttributes) {
      this.iconicIcon = iconicIcon;
      this.dataAttributes = dataAttributes || {};
    }
  }

  class SizeData {
    constructor(bulmaIconClass, iconicSize, customClass) {
      this.bulmaIconClass = bulmaIconClass;
      this.iconicSize = iconicSize || null;
      this.customIconClass = customClass || null;
    }
  }
  
  const iconMap = {
    'caret-bottom': new IconData('caret', {'data-direction': 'bottom'}),
    'caret-top': new IconData('caret', {'data-direction': 'top'}),
    check: new IconData('check'),
    'circle-check': new IconData('circle-check'),
    'link-broken': new IconData('link', {'data-state': 'broken'}),
    'link-intact': new IconData('link', {'data-state': 'intact'}),
    'lock-locked': new IconData('lock', {'data-state': 'locked'}),
    'lock-unlocked': new IconData('lock', {'data-state': 'unlocked'}),
    menu: new IconData('menu'),
    pencil: new IconData('pencil'),
    person: new IconData('person'),
    'question-mark': new IconData('question-mark'),
    star: new IconData('star'),
    'star-empty': new IconData('star-empty'),
    warning: new IconData('warning'),
    x: new IconData('x')
  };

  const sizeMap = {
    xs: new SizeData('is-small', 'sm', 'is-xs'),
    sm: new SizeData('is-small', 'sm'),
    md: new SizeData('', 'sm', 'is-md'),
    lg: new SizeData('is-medium', 'md'),
    xl: new SizeData('is-large', 'md', 'is-xl')
  };

  export default {
    emits: ["click"],
    props: {
      icon: {
        validator: (i) => iconMap[i] !== undefined
      },
      size: {
        required: false,
        type: String,
        validator: (s) => sizeMap[s] !== undefined,
        default: 'md'
      },
      padding: {
        type: String,
        required: false,
        default: null
      }
    },

    setup(props) {
      const iconData = computed(() => iconMap[props.icon]);
      const sizeData = computed(() => sizeMap[props.size]);
      const iconClasses = computed(() => [sizeData.value.bulmaIconClass, sizeData.value.customIconClass]);
      const iconicSize = computed(() => sizeData.value.iconicSize);
      const iconicIcon = computed(() => iconData.value.iconicIcon);
      const iconicAttributes = computed(() => iconData.value.dataAttributes);

      return {
        iconClasses,
        iconData,
        sizeData,
        iconicAttributes,
        iconicIcon,
        iconicSize
      }
    }
  }

</script>

<style lang="scss" scoped>

  span.icon.is-xs {
    svg.iconic {
      width: 8px;
      height: 8px;
    }
  }

  span.icon.is-sm {
    svg.iconic {
      width: 12px;
      height: 12px;
    }
  }


  span.icon.is-md {
    svg.iconic {
      width: 16px;
      height: 16px;
    }
  }

  span.icon.is-lg {
    svg.iconic {
      width: 32px;
      height: 32px;
    }
  }

  span.icon.is-xl {
    svg.iconic {
      width: 48px;
      height: 48px;
    }
  }


</style>