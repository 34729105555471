import { computed, ref } from "vue";
import { useAppConfigStore } from "../stores/appConfig";

export function useLoadResource() {
  const appConfig = useAppConfigStore();
  const localLoadingCount = ref(0);
  const localLoading = computed(() => localLoadingCount.value > 0);

  const loadResource = async (promise) => {
    appConfig.setLoading(true);
    localLoadingCount.value = localLoadingCount.value + 1;
    try {
      return await promise;
    } catch (error) {
      appConfig.setError(error);
    } finally {
      appConfig.setLoading(false);
      localLoadingCount.value = localLoadingCount.value - 1;
    }
  };

  return {
    loadResource,
    localLoading,
    localLoadingCount
  };
}
