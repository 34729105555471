import '../styles';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { swInit } from "../lib/ServiceWorker";
import config from '../lib/config';
import { installClickStrike } from "../lib/ClickStrike";
import router from '../router';

import AppAutocomplete from "../components/AppAutocomplete";
import AppConfirm from "../components/AppConfirm";
import AppDateTime from "../components/AppDateTime";
import AppDatePicker from "../components/AppDatePicker";
import AppDropdown from "../components/AppDropdown";
import AppExpandTransition from "../components/AppExpandTransition";
import AppIcon from "../components/AppIcon";
import AppIconicIcon from "../components/AppIconicIcon";
import AppModal from "../components/AppModal";
import AppNavbar from "../components/AppNavbar";
import AppPager from "../components/AppPager";
import AppRating from "../components/AppRating";
import AppSearchText from "../components/AppSearchText";
import AppTagEditor from "../components/AppTagEditor";
import AppTextField from "../components/AppTextField";
import AppValidationErrors from "../components/AppValidationErrors";

import App from '../components/App.vue'

// Resize?
// Progressbar?

document.addEventListener('DOMContentLoaded', () => {
  const appElement = document.getElementById('app');
  if (!appElement) { return; }

  config.baseApiUrl = appElement.dataset.url;

  const app = createApp(App);
  const pinia = createPinia();
  app.use(pinia);
  app.use(router);
  swInit();
  installClickStrike(app);

  app.component("AppAutocomplete", AppAutocomplete);
  app.component("AppConfirm", AppConfirm);
  app.component("AppDateTime", AppDateTime);
  app.component("AppDatePicker", AppDatePicker);
  app.component("AppDropdown", AppDropdown);
  app.component("AppExpandTransition", AppExpandTransition);
  app.component("AppIcon", AppIcon);
  app.component("AppIconicIcon", AppIconicIcon);
  app.component("AppModal", AppModal);
  app.component("AppNavbar", AppNavbar);
  app.component("AppPager", AppPager);
  app.component("AppRating", AppRating);
  app.component("AppSearchText", AppSearchText);
  app.component("AppTagEditor", AppTagEditor);
  app.component("AppTextField", AppTextField);
  app.component("AppValidationErrors", AppValidationErrors);
  
  app.mount(appElement);
});
