<template>
  <div>

    <div v-if="recipe === null">
      Loading...
    </div>
    <div v-else>
      <h1 class="title">Editing {{ recipe.name || "[Unamed Recipe]" }}</h1>

      <app-validation-errors :errors="validationErrors"></app-validation-errors>

      <recipe-edit :recipe="recipe"></recipe-edit>
    </div>

    <button type="button" class="button is-primary" @click="save">Save</button>
    <router-link class="button is-secondary" to="/">Cancel</router-link>

  </div>
</template>

<script setup>

  import { computed, onBeforeMount, ref } from "vue";
  import { useRoute, useRouter } from "vue-router";
  import { useLoadResource } from "../lib/useLoadResource";
  import RecipeEdit from "./RecipeEdit";
  import api from "../lib/Api";
  import * as Errors from '../lib/Errors';

  const { loadResource } = useLoadResource();
  const route = useRoute();
  const router = useRouter();
  const recipe = ref(null);
  const validationErrors = ref({});

  const recipeId = computed(() => route.params.id);

  onBeforeMount(() => {
    loadResource(
        api.getRecipe(recipeId.value, null, null, null, data => { recipe.value = data; return data; })
    );
  });

  function save() {
    validationErrors.value = {};
    loadResource(
        api.patchRecipe(recipe.value)
            .then(() => router.push({name: 'recipe', params: {id: recipeId.value }}))
            .catch(Errors.onlyFor(Errors.ApiValidationError, err => validationErrors.value = err.validationErrors()))
    );
  }


</script>

<style lang="scss" scoped>
</style>