<template>
  <div>

    <h1 class="title">Edit User</h1>

    <app-validation-errors :errors="validationErrors"></app-validation-errors>

    <user-edit v-if="userObj != null" :user-obj="userObj" action="Creating"></user-edit>

    <button type="button" class="button is-primary" @click="save">Save</button>
    <router-link class="button is-secondary" to="/">Cancel</router-link>

  </div>
</template>

<script setup>

  import { ref, watch } from "vue";
  import { useRouter } from "vue-router";
  import UserEdit from "./UserEdit";
  import api from "../lib/Api";
  import * as Errors from '../lib/Errors';
  import { useAppConfigStore } from "../stores/appConfig";
  import { useLoadResource } from "../lib/useLoadResource";
  import { useCheckAuthentication } from "../lib/useCheckAuthentication";

  const appConfig = useAppConfigStore();
  const { loadResource } = useLoadResource();
  const { checkAuthentication } = useCheckAuthentication(loadResource);
  const router = useRouter();

  const validationErrors = ref({});
  const userObj = ref(null);

  watch(
      () => appConfig.user,
      () => refreshUser(),
      { immediate: true });

  function refreshUser() {
    if (appConfig.user) {
      userObj.value = {
        username: appConfig.user.username,
        full_name: appConfig.user.full_name,
        email: appConfig.user.email,
        password: '',
        password_confirmation: ''
      };
    } else {
      userObj.value = null;
    }
  }

  function save() {
    validationErrors.value = {};
    loadResource(
        api.patchUser(userObj.value)
            .then(() => checkAuthentication())
            .then(() => {
              router.push('/');
            })
            .catch(Errors.onlyFor(Errors.ApiValidationError, err => validationErrors.value = err.validationErrors()))
    );
  }

</script>

<style lang="scss" scoped>

</style>