import { useAppConfigStore } from "../stores/appConfig";

export function useCheckAuthentication(loadResource) {
  const appConfig = useAppConfigStore();
  const checkAuthentication = function() {
    return loadResource(appConfig.updateCurrentUser());
  }

  return {
    checkAuthentication
  }
}
