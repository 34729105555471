import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useNutrientStore = defineStore('nutrient', () => {
  const nutrientList = ref({
    kcal: { label: "Calories", unit: "kcal" },
    protein: { label: "Protein", unit: "g" },
    lipids: { label: "Fat", unit: "g" },
    carbohydrates: { label: "Carbohydrates", unit: "g" },
    water: { label: "Water", unit: "g" },
    sugar: { label: "Sugar", unit: "g" },
    fiber: { label: "Fiber", unit: "g" },
    cholesterol: { label: "Cholesterol", unit: "mg" },
    sodium: { label: "Sodium", unit: "mg" },
    calcium: { label: "Calcium", unit: "mg" },
    iron: { label: "Iron", unit: "mg" },
    magnesium: { label: "Magnesium", unit: "mg" },
    phosphorus: { label: "Phosphorus", unit: "mg" },
    potassium: { label: "Potassium", unit: "mg" },
    zinc: { label: "Zinc", unit: "mg" },
    copper: { label: "Copper", unit: "mg" },
    manganese: { label: "Manganese", unit: "mg" },
    vit_a: { label: "Vitamin A", unit: "μg" },
    vit_b6: { label: "Vitamin B6", unit: "mg" },
    vit_b12: { label: "Vitamin B12", unit: "μg" },
    vit_c: { label: "Vitamin C", unit: "mg" },
    vit_d: { label: "Vitamin D", unit: "μg" },
    vit_e: { label: "Vitamin E", unit: "mg" },
    vit_k: { label: "Vitamin K", unit: "μg" },
    ash: { label: "ash", unit: "g" }
  });

  return {
    nutrientList
  };
});