<template>
  <div>

    <h1 class="title">Creating {{ recipe.name || "[Unamed Recipe]" }}</h1>

    <app-validation-errors :errors="validationErrors"></app-validation-errors>

    <recipe-edit :recipe="recipe" action="Creating"></recipe-edit>

    <button type="button" class="button is-primary" @click="save">Save</button>
    <router-link class="button is-secondary" to="/">Cancel</router-link>

  </div>
</template>

<script setup>

  import { ref } from "vue";
  import { useRouter } from "vue-router";
  import RecipeEdit from "./RecipeEdit";
  import api from "../lib/Api";
  import * as Errors from '../lib/Errors';
  import { useLoadResource } from "../lib/useLoadResource";

  const router = useRouter();
  const { loadResource } = useLoadResource();

  const validationErrors = ref({});
  const recipe = ref({
    name: null,
    source: null,
    description: null,
    yields: null,
    total_time: null,
    active_time: null,
    step_text: null,
    tags: [],
    ingredients: []
  });

  function save() {
    validationErrors.value = {};
    loadResource(
        api.postRecipe(recipe.value)
            .then(() => router.push('/'))
            .catch(Errors.onlyFor(Errors.ApiValidationError, err => validationErrors.value = err.validationErrors()))
    );
  }

</script>

<style lang="scss" scoped>
</style>