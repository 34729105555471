<template>
  <div>
    <h1>404!</h1>
    <p>WTF?</p>
  </div>
</template>

<script setup>


</script>