import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useAppConfigStore } from "./appConfig";
import api from "../lib/Api";
import { createChannel } from "../lib/ActionCable";

export const useTaskStore = defineStore('task', () => {
  const taskLists = ref([]);
  const currentTaskList = ref(null);
  let taskChannel = null;

  function replaceTaskList(list) {
    if (taskLists.value) {
      const listIdx = taskLists.value.findIndex(l => l.id === list.id);
      if (listIdx >= 0) {
        taskLists.value.splice(listIdx, 1, list);
      }
      if (currentTaskList.value && currentTaskList.value.id === list.id) {
        currentTaskList.value = list;
      }
    }
  }

  function ensureTaskListChannel() {
    if (taskChannel === null) {
      taskChannel = createChannel(null, "TaskChannel", {
        received(data) {
          if (data && data.action === 'updated') {
            replaceTaskList(data.task_list);
          }
        }
      });
    }
  }

  function refreshTaskLists() {
    const cb = function(data) {
      taskLists.value = data || [];
      let ctl = null;

      if (currentTaskList.value) {
        ctl = data.find(l => l.id === currentTaskList.value.id);
      }

      ctl = ctl || data[0] || null;
      setCurrentTaskList(ctl);
      ensureTaskListChannel();
    };

    return api.getTaskLists(cb)
  }

  function ensureTaskLists() {
    const appConfig = useAppConfigStore();

    if (appConfig.user && taskLists.value.length === 0) {
      return refreshTaskLists();
    } else {
      return Promise.resolve();
    }
  }

  function setCurrentTaskList(taskList) {
    currentTaskList.value = taskList || null;
  }

  async function createTaskList(newList) {
    currentTaskList.value = await api.postTaskList(newList);
    return refreshTaskLists();
  }

  async function deleteTaskList(taskList) {
    await api.deleteTaskList(taskList);
    return refreshTaskLists();
  }

  function createTaskItem(taskItem) {
    return api.postTaskItem(taskItem.task_list_id, taskItem)
      .then(data => {
        return data;
      });
  }

  function updateTaskItem(taskItem) {
    return api.patchTaskItem(taskItem.task_list_id, taskItem)
      .then(data => {
        return data;
      });
  }

  function deleteTaskItems(payload) {
    return api.deleteTaskItems(payload.taskList.id, payload.taskItems);
  }

  function completeTaskItems(payload) {
    return api.completeTaskItems(payload.taskList.id, payload.taskItems, !payload.completed);
  }

  return {
    currentTaskList,
    taskLists,

    createTaskList,
    deleteTaskList,
    ensureTaskLists,
    refreshTaskLists,
    setCurrentTaskList,

    createTaskItem,
    updateTaskItem,
    deleteTaskItems,
    completeTaskItems
  };
});