<template>
  <span>
    <input v-if="useInput" class="text" type="text" readonly :value="friendlyString" />
    <span v-else :title="fullString">{{ friendlyString }}</span>
  </span>
</template>

<script setup>

  import { computed } from "vue";
  import DateTimeUtils from "../lib/DateTimeUtils";

  const props = defineProps({
    dateTime: {
      required: true,
      type: [Date, String]
    },

    showDate: {
      required: false,
      type: Boolean,
      default: true
    },

    showTime: {
      required: false,
      type: Boolean,
      default: true
    },

    useInput: {
      required: false,
      type: Boolean,
      default: false
    }
  });

  const dateObj = computed(() => DateTimeUtils.toDate(props.dateTime));
  const fullString = computed(() => DateTimeUtils.formatTimestamp(dateObj.value));

  const friendlyString = computed(() => {
    const parts = [];
    if (props.showDate) {
      parts.push(DateTimeUtils.formatDate(dateObj.value));
    }
    if (props.showTime) {
      parts.push(DateTimeUtils.formatTime(dateObj.value, true));
    }
    return parts.join(" ");
  });

</script>

<style lang="scss" scoped>
</style>