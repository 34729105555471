import { createRouter, createWebHashHistory } from "vue-router";
import { nextTick } from "vue";

import The404Page from '../components/The404Page';
import TheAboutPage from '../components/TheAboutPage';
import TheCalculator from '../components/TheCalculator';

import TheLog from '../components/TheLog';
import TheLogList from '../components/TheLogList';
import TheLogCreator from '../components/TheLogCreator';
import TheLogEditor from '../components/TheLogEditor';

import TheFoodList from '../components/TheFoodList';
import TheFood from "../components/TheFood";
import TheFoodEditor from "../components/TheFoodEditor";
import TheFoodCreator from "../components/TheFoodCreator";
import TheNotesList from '../components/TheNotesList';
import TheRecipe from '../components/TheRecipe';
import TheRecipeEditor from '../components/TheRecipeEditor';
import TheRecipeCreator from '../components/TheRecipeCreator';
import TheRecipeList from '../components/TheRecipeList';

import TheTaskListList from '../components/TheTaskListList';

import TheUserCreator from '../components/TheUserCreator';
import TheUserEditor from '../components/TheUserEditor';

import TheAdminUserList from '../components/TheAdminUserList';
import TheAdminUserEditor from '../components/TheAdminUserEditor';

import { useAppConfigStore } from "../stores/appConfig";


const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: '/',
      redirect: '/recipes'
    },
    {
      path: '/recipes',
      name: 'recipeList',
      component: TheRecipeList,
      props: route => ({
        searchQuery: {
          name: route.query.name,
          tags: route.query.tags,
          column: route.query.column,
          direction: route.query.direction,
          page: route.query.page,
          per: route.query.per
        }
      }),
      meta: {
        handleInitialLoad: true
      }
    },
    {
      path: '/recipes/new',
      name: 'new_recipe',
      component: TheRecipeCreator
    },
    {
      path: '/recipes/:id/edit',
      name: 'edit_recipe',
      component: TheRecipeEditor
    },
    {
      path: '/recipe/:id',
      name: 'recipe',
      component: TheRecipe
    },
    {
      path: "/about",
      name: "about",
      component: TheAboutPage
    },
    {
      path: "/calculator",
      name: "calculator",
      component: TheCalculator
    },
    {
      path: "/foods",
      name: "foods",
      component: TheFoodList
    },
    {
      path: "/foods/new",
      name: "new_food",
      component: TheFoodCreator
    },
    {
      path: "/foods/:id/edit",
      name: "edit_food",
      component: TheFoodEditor
    },
    {
      path: "/foods/:id",
      name: "food",
      component: TheFood
    },
    {
      path: "/logs",
      name: "logs",
      component: TheLogList
    },
    {
      path: "/recipes/:recipeId/logs/new",
      name: "new_log",
      component: TheLogCreator
    },
    {
      path: "/logs/:id/edit",
      name: "edit_log",
      component: TheLogEditor
    },
    {
      path: "/logs/:id",
      name: "log",
      component: TheLog
    },
    {
      path: "/notes",
      name: "notes",
      component: TheNotesList
    },
    {
      path: "/tasks",
      name: "task_lists",
      component: TheTaskListList
    },
    {
      path: "/logout",
      name: "logout",
      beforeEnter: async (to, from, next) => {
        const appConfig = useAppConfigStore();
        await appConfig.logout();
        return next("/");
      }
    },

    {
      path: "/user/new",
      name: "new_user",
      component: TheUserCreator
    },
    {
      path: "/user/edit",
      name: "edit_user",
      component: TheUserEditor
    },

    {
      path: "/admin/users",
      name: "admin_users",
      component: TheAdminUserList
    },

    {
      path: "/admin/users/:id/edit",
      name: "admin_edit_user",
      component: TheAdminUserEditor
    },

    {
      path: '/:pathMatch(.*)*',
      name: 'NotFound',
      component: The404Page
    }
  ]
});

router.afterEach((to, from) => {
  const appConfigStore = useAppConfigStore();
  if (to.meta.handleInitialLoad !== true && appConfigStore.initialLoad === false) {
    appConfigStore.initialLoad = true;
  }

  nextTick(() => {
    document.title = to.meta.title || 'Parsley';
  });
});

export default router;
