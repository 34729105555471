<template>
  <div>
    <h1 class="title is-3">
      Tasks
      <app-dropdown button-class="is-primary" :open="showListDropdown" :label="listSelectLabel" @open="showListDropdown = true" @close="showListDropdown = false">

        <task-list-dropdown-item v-for="l in taskLists" :key="l.id" :task-list="l" :active="currentTaskList !== null && currentTaskList.id === l.id" @select="selectList" @delete="deleteList"></task-list-dropdown-item>

        <hr class="dropdown-divider" v-if="taskLists.length > 0">
        <div class="dropdown-item">
          <task-list-mini-form :task-list="newList" :validation-errors="newListValidationErrors" @save="saveNewList"></task-list-mini-form>
        </div>
      </app-dropdown>
    </h1>

    <div class="columns" v-if="currentTaskList !== null">
      <div class="column is-6-widescreen is-8-desktop is-10-tablet">
        <task-item-list :task-list="currentTaskList"></task-item-list>
      </div>
    </div>

  </div>
</template>

<script setup>

  import { computed, onBeforeMount, ref } from "vue";
  import * as Errors from '../lib/Errors';
  import { useTaskStore } from "../stores/task";

  import TaskListMiniForm from "./TaskListMiniForm";
  import TaskListDropdownItem from "./TaskListDropdownItem";
  import TaskItemList from "./TaskItemList";
  import { useLoadResource } from "../lib/useLoadResource";

  const newListTemplate = function() {
    return {
      name: ''
    };
  };

  const { loadResource } = useLoadResource();
  const taskStore = useTaskStore();

  const showListDropdown = ref(false);
  const newList = ref(newListTemplate());
  const newListValidationErrors = ref({});

  const taskLists = computed(() => taskStore.taskLists);
  const currentTaskList = computed(() => taskStore.currentTaskList);
  const listSelectLabel = computed(() => {
    if (currentTaskList.value === null) {
      return "Select or Create a List";
    } else {
      return currentTaskList.value.name;
    }
  });

  onBeforeMount(() => {
    loadResource(taskStore.refreshTaskLists());
  });

  function selectList(list) {
    taskStore.setCurrentTaskList(list);
    showListDropdown.value = false;
  }

  function saveNewList() {
    loadResource(
        taskStore.createTaskList(newList.value)
            .then(() => showListDropdown.value = false)
            .then(() => { newList.value = newListTemplate(); newListValidationErrors.value = {}; } )
            .catch(Errors.onlyFor(Errors.ApiValidationError, err => newListValidationErrors.value = err.validationErrors()))
    );
  }

  function deleteList(list) {
    loadResource(taskStore.deleteTaskList(list));
  }

  function deleteAllItems() {
    loadResource(
        taskStore.deleteTaskItems({
          taskList: currentTaskList.value,
          taskItems: currentTaskList.value.task_items
        })
    );
  }

</script>

<style lang="scss" scoped>

</style>