
function clickStrikeClick(evt) {
  const isStrikable = el => el && el.tagName === "LI";
  const strikeClass = "is-strikethrough";

  let t = evt.target;

  while (t !== null && t !== this && !isStrikable(t)) {
    t = t.parentElement;
  }

  if (isStrikable(t)) {
    const classList = t.className.split(" ");
    const strIdx = classList.findIndex(c => c === strikeClass);
    if (strIdx >= 0) {
      classList.splice(strIdx, 1);
    } else {
      classList.push(strikeClass);
    }

    t.className = classList.join(" ");
  }
}

export function installClickStrike(app) {
  app.directive('click-strike', {
    beforeMount(el) {
      el.addEventListener("click", clickStrikeClick);
    },

    unmounted(el) {
      el.removeEventListener("click", clickStrikeClick);
    }
  });
}
