<template>
  <div>
    <h1 class="title">
      Log Entries
    </h1>

    <table class="table">
      <thead>
      <tr>
        <th>Recipe</th>
        <th>Date</th>
        <th>Rating</th>
        <th>Notes</th>
      </tr>
      </thead>

      <tbody>
      <tr v-for="l in logs" :key="l.id">
        <td> <router-link :to="{name: 'log', params: {id: l.id}}">{{l.recipe.name}}</router-link></td>
        <td><app-date-time :date-time="l.date" :show-time="false"></app-date-time> </td>
        <td><app-rating :model-value="l.rating" readonly></app-rating></td>
        <td>{{l.notes}}</td>
      </tr>
      </tbody>

    </table>
  </div>
</template>

<script setup>

  import { computed, reactive, ref, watch } from "vue";
  import api from "../lib/Api";
  import debounce from "lodash/debounce";
  import { useLoadResource } from "../lib/useLoadResource";

  const { loadResource } = useLoadResource();

  const logData = ref(null);
  const search = reactive({
    page: 1,
    per: 25
  });

  const logs = computed(() => logData.value?.logs || []);
  const totalPages = computed(() => logData.value?.total_pages || 0);
  const currentPage = computed(() => logData.value?.current_page || 0);

  const getList = debounce(function() {
    loadResource(
        api.getLogList(search.page, search.per)
            .then(data => logData.value = data)
    );
  }, 500, {leading: true, trailing: true});

  watch(search,
      () => getList(),
      {
        deep: true,
        immediate: true
      }
  );

  function changePage(idx) {
    search.page = idx;
  }

</script>

<style lang="scss" scoped>
</style>