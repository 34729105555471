<template>
  <div class="columns is-mobile edit-ingredient-item">
    <div class="column">

      <div class="columns is-multiline is-mobile">
        <div class="column is-half-mobile is-2-tablet">
          <span class="label is-small-mobile" v-if="showLabels">Quantity</span>
          <input class="input is-small-mobile" type="text" v-model="ingredient.quantity">
        </div>
        <div class="column is-half-mobile is-3-tablet">
          <span class="label is-small-mobile" v-if="showLabels">Units</span>
          <input class="input is-small-mobile"  type="text" v-model="ingredient.units">
        </div>
        <div class="column is-half-mobile is-3-tablet">
          <span class="label is-small-mobile" v-if="showLabels">Name</span>

          <app-autocomplete
              :inputClass="{'is-small-mobile': true, 'is-success': ingredient.ingredient_id !== null}"
              ref="autocomplete"
              v-model="ingredient.name"
              :minLength="2"
              valueAttribute="name"
              labelAttribute="name"
              placeholder=""
              @inputClick="nameClick"
              @optionSelected="searchItemSelected"
              :onGetOptions="updateSearchItems"
          >
          </app-autocomplete>
        </div>
        <div class="column is-half-mobile is-4-tablet">
          <span class="label is-small-mobile" v-if="showLabels">Preparation</span>
          <input class="input is-small-mobile"  type="text" v-model="ingredient.preparation">
        </div>
      </div>

    </div>
    <div class="column is-narrow">
      <span class="label is-small-mobile" v-if="showLabels">&nbsp;</span>
      <button type="button" class="button is-danger is-small" @click="deleteFood(ingredient)">
        <app-icon icon="x" size="md"></app-icon>
      </button>
    </div>
  </div>
</template>

<script setup>

  import { useTemplateRef, watch } from "vue";
  import api from "../lib/Api";

  const emit = defineEmits(["deleteFood"]);
  const props = defineProps({
    ingredient: {
      required: true,
      type: Object
    },
    showLabels: {
      required: false,
      type: Boolean,
      default: false
    }
  });

  const autocompleteElement = useTemplateRef("autocomplete");

  watch(props.ingredient, (val) => {
    if (props.ingredient.ingredient && props.ingredient.ingredient.name !== val) {
      props.ingredient.ingredient_id = null;
      props.ingredient.ingredient = null;
    }
  });

  function deleteFood(ingredient) {
    emit("deleteFood", ingredient);
  }

  function updateSearchItems(text) {
    return api.getSearchIngredients(text);
  }

  function searchItemSelected(ingredient) {
    props.ingredient.ingredient_id = ingredient.id;
    props.ingredient.ingredient = ingredient;
    props.ingredient.name = ingredient.name;
  }

  function nameClick() {
    if (props.ingredient.ingredient_id === null && props.ingredient.name !== null && props.ingredient.name.length > 2) {
      autocompleteElement.updateOptions(props.ingredient.name);
    }
  }

</script>

<style lang="scss" scoped>

  @use "bulma/sass/utilities" as bulma;

  .edit-ingredient-item {
    border-bottom: solid 1px bulma.$grey-light;
    margin-bottom: 1.25rem;

    &:last-child {
      border-bottom: none;
    }
  }

</style>