<template>
  <nav v-show="totalPages > 1 || showWithSinglePage" class="pagination" role="navigation" :aria-label="pagedItemName + ' page navigation'">
    <a :class="{'pagination-previous': true, 'is-disabled': isFirstPage}" :title="isFirstPage ? 'This is the first page' : ''" @click.prevent="changePage(currentPage - 1)">Previous</a>
    <a :class="{'pagination-next': true, 'is-disabled': isLastPage}" :title="isLastPage ? 'This is the last page' : ''" @click.prevent="changePage(currentPage + 1)">Next page</a>
    <ul class="pagination-list">
      <li v-for="page in pageItems" :key="page">
        <a v-if="page > 0" class="pagination-link" :class="{'is-current': page === currentPage}" href="#" @click.prevent="changePage(page)">{{page}}</a>
        <span v-else class="pagination-ellipsis">&hellip;</span>
      </li>
    </ul>
  </nav>
</template>

<script setup>

import { computed } from "vue";

const emit = defineEmits(["changePage"]);

const props = defineProps({
  pagedItemName: {
    required: false,
    type: String,
    default: ''
  },

  currentPage: {
    required: true,
    type: Number
  },

  totalPages: {
    required: true,
    type: Number
  },

  pageWindow: {
    required: false,
    type: Number,
    default: 4
  },

  pageOuterWindow: {
    required: false,
    type: Number,
    default: 1
  },

  showWithSinglePage: {
    required: false,
    type: Boolean,
    default: false
  }
});

const pageItems = computed(() => {
  const items = new Set();

  for (let x = 0; x < props.pageOuterWindow; x++) {
    items.add(x + 1);
    items.add(props.totalPages - x);
  }

  const start = props.currentPage - Math.ceil(props.pageWindow / 2);
  const end = props.currentPage + Math.floor(props.pageWindow / 2);

  for (let x = start; x <= end; x++) {
    items.add(x);
  }

  let emptySpace = -1;
  const finalList = [];

  [...items.values()].filter(p => p > 0 && p <= props.totalPages).sort((a, b) => a - b).forEach((p, idx, list) => {
    finalList.push(p);
    if (list[idx + 1] && list[idx + 1] !== p + 1) {
      finalList.push(emptySpace--);
    }
  });

  return finalList;
});

const isLastPage = computed(() => props.currentPage === props.totalPages);
const isFirstPage = computed(() => props.currentPage === 1);

function changePage(idx) {
  emit("changePage", idx);
}

</script>
