<template>
  <div>

    <h1 class="title">Create New User</h1>

    <app-validation-errors :errors="validationErrors"></app-validation-errors>

    <user-edit :user-obj="userObj" action="Creating"></user-edit>

    <button type="button" class="button is-primary" @click="save">Save</button>
    <router-link class="button is-secondary" to="/">Cancel</router-link>

  </div>
</template>

<script setup>

  import { ref } from "vue";
  import { useRouter } from "vue-router";
  import UserEdit from "./UserEdit";
  import api from "../lib/Api";
  import * as Errors from '../lib/Errors';
  import { useLoadResource } from "../lib/useLoadResource";
  import { useCheckAuthentication } from "../lib/useCheckAuthentication";

  const { loadResource } = useLoadResource();
  const { checkAuthentication } = useCheckAuthentication(loadResource);
  const router = useRouter();

  const validationErrors = ref({});
  const userObj = ref({
    username: '',
    full_name: '',
    email: '',
    password: '',
    password_confirmation: ''
  });

  function save() {
    validationErrors.value = {};
    loadResource(
        api.postUser(userObj.value)
            .then(() => checkAuthentication())
            .then(() => router.push('/'))
            .catch(Errors.onlyFor(Errors.ApiValidationError, err => validationErrors.value = err.validationErrors()))
    );
  }

</script>

<style lang="scss" scoped>

</style>