<template>
  <div>
    <div v-if="food === null">
      Loading...
    </div>
    <div v-else>
      <food-show :food="food"></food-show>
    </div>

    <router-link v-if="appConfig.isLoggedIn" class="button" :to="{name: 'edit_food', params: { id: foodId }}">Edit</router-link>
    <router-link class="button" to="/foods">Back</router-link>
  </div>
</template>

<script setup>

  import { computed, onBeforeMount, ref } from "vue";
  import { useRoute } from "vue-router";
  import FoodShow from "./FoodShow";
  import api from "../lib/Api";
  import { useLoadResource } from "../lib/useLoadResource";
  import { useAppConfigStore } from "../stores/appConfig";

  const { loadResource } = useLoadResource();
  const appConfig = useAppConfigStore();
  const route = useRoute();

  const food = ref(null);
  const foodId = computed(() => route.params.id);

  onBeforeMount(() => {
    loadResource(
        api.getFood(foodId.value)
            .then(data => { food.value = data; return data; })
    );
  });

</script>

<style lang="scss" scoped>
</style>